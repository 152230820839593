import React,{useState} from "react";
import { useForm } from 'react-hook-form';
import axios from "axios";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from "../Context/AppContext";
import { loginBanner, loginVideo } from "../Assets/Visuals";

import { toast} from 'react-toastify';

import { GridLoader } from 'react-spinners';

function Login() {
  const { login} = useAuth();
  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    LoginID: yup.string().required(),
    Password: yup.string().required()
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post('https://IcreativezQuizApi.icreativez.com//api//Account/LoginNew',data);
  
      const responseData = response.data;
      console.log(responseData);
  
      if (responseData.Message === 'Success') {
         if (responseData.user.IsAlreadyAttempt > 0 || responseData.user.IsLogin > 0){
          toast.error('You have already Logged In or Already Attempted',{
            autoClose : 1500
          })
          return;
         }
        localStorage.setItem('token', responseData.Token.AccessToken);
        localStorage.setItem('userId', responseData.user.UserId);
        localStorage.setItem('fullName', `${responseData.user.FirstName} ${responseData.user.LastName}`);
        login();
  
      } else {
        console.error('Login failed with unexpected response:', responseData);
        toast.error('Login failed with unexpected response', {
          autoClose: 1500
        });
      }
  
    } catch (error) {
      toast.error('Login Failed', {
        autoClose: 1500
      });
    } finally {
      setLoading(false); 
    }
  };
  
  
  return (
    
    <section id="Login-Page" className="d-flex align-items-center justify-content-center">
      {loading ? <GridLoader color={'#85030a'} size={15} margin={2} /> : 
      <>
      <video
        autoPlay
        muted
        loop
        className="video-background"
      >
        <source src={loginVideo} type="video/mp4" />
      </video>
      <div className="overlay"></div>
      <div id="Login-Form">
        <div className="login-banner" style={{ backgroundImage: `url(${loginBanner})` }}></div>
        <div className="login-info p-4 p-md-5">
          <h3>Login to <b style={{ color: "#85030a" }}>icreativez LMS</b></h3>
          <p className="mb-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus ut mollitia odio doloremque voluptates temporibus consectetur.
          </p>
          <form className="FormInputs" onSubmit={handleSubmit(handleLogin)}>
            <div className="input-box">
              <input type="text" placeholder='LoginID' {...register('LoginID')} />
              <p style={{ color: 'red' }}><small>{errors.LoginID?.message}</small></p>
            </div>
            <div className="input-box">
              <input type="password" placeholder='Password' {...register('Password')} />
              <p style={{ color: 'red' }}><small>{errors.Password?.message}</small></p>
            </div>
            <div className="InputSubmit mb-3">
              <button type='submit' className="btn" style={{ width: '100%', background: '#85030a', color: '#fff' }}>Login</button>
            </div>
            <div className="InputForget d-flex">
              <div className="w-50 text-left">
                <label className="checkbox-wrap checkbox-primary mb-0">
                  Remember Me
                  <input type="checkbox" className="ms-2" defaultChecked="" />
                </label>
              </div>
              <div className="w-50 text-md-right d-flex" style={{ flexDirection: 'row-reverse' }}>
                <a href="/" rel="noreferrer noopenner">Forgot Password</a>
              </div>
            </div>
          </form>
        </div>
      </div>
      </>}
    </section>
  );
}

export default Login;
