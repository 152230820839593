import React from 'react'
import { useAuth } from '../Context/AppContext'

const ResultPop = () => {
  const {obtainedMarks,totalMarks,logout,timeTaken} = useAuth();
  const userId = localStorage.getItem("userId")
  return (
    <div className='d-flex align-item-center justify-content-center'>
        <div className='d-flex align-items-center flex-column resultPopUp' style={{rowGap:'30px'}}>
            <b>Final Result</b>
            <p><b>User Id : </b> {userId}</p>
            <p>{obtainedMarks} out of {totalMarks}</p>
            <p><b>Time Taken : </b>{timeTaken}</p>
            <div className="d-flex justify-content-center align-items-center my-4">
              <button className='btn btn-lg test-start-btn text-white' onClick={logout}> logout</button>
            </div>
        </div>
    </div>
  )
}

export default ResultPop
