import React from 'react'
import Home from '../Pages/Dashboard/Home'
import {Routes,Route} from 'react-router-dom'
import DashboardNavbar from '../Components/DashboardNavbar'
import DashboardSidebar from '../Components/DashboardSidebar'
import { useAuth } from '../Context/AppContext'

const DashboardRoutes = () => {
  const {sidebarOpen} = useAuth();
  return (
    <>
    <DashboardSidebar />
    <section id='dashboard-main' className={`main_content ${sidebarOpen ? 'open' : ''}`}>
      <DashboardNavbar />
      <div class="main_content_iner">
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </section>
  </>
  )
}

export default DashboardRoutes
