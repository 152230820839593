import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../Context/AppContext";

const Instructions = () => {
  const {testStart} = useAuth();
  return (
    <>
    <header className="text-white">
      <div className="container p-4 text-center">
        <h1 className="fw-bolder">Welcome to demoTest</h1>
        <p className="lead">
          Before Starting the test Read out the instructions below
        </p>
        <Link className="btn btn-lg test-start-btn text-white" onClick={testStart}>
          Start Test
        </Link>
      </div>
    </header>
    <section id="Instructions" className="pt-4">
      <div className="container px-4">
        <div className="row gx-4 justify-content-center">
          <div className="col-lg-8">
            <h2 style={{color:'#85030a'}}>Online Exam Terms and Conditions:</h2>
            <ul style={{listStyle:'disc'}}>
              <li>
                Each question Carries equal mark
              </li>
              <li>
              Only 45 seconds are allowed for each question
              </li>
              <li>
              There will be no negative marking
              </li>
              <li>
              Make Sure you have a good internet connection
              </li>
              <li>
                Your account will be blockedif you tried to logout without submitting your quiz
              </li>
              <li>
              Press submit button to submit the test
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    </>
  );
};

export default Instructions;
