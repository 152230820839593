import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ResultPop from './ResultPop';
import { GridLoader } from 'react-spinners';
import { toast} from 'react-toastify';
import { useAuth } from '../Context/AppContext';

const MCQsTest = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timer, setTimer] = useState(45);
  const [Result, setResult] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [examResponses, setExamResponses] = useState([]);
  const {setObtainedMarks,setTotalMarks, setStartTest,setTimeTaken } = useAuth();
  const [TSTime, setTSTime] = useState(null);
  const [submissionInProgress, setSubmissionInProgress] = useState(false);

  useEffect(() => {
    const getQuestions = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get('https://IcreativezQuizApi.icreativez.com//api//Corporate//GetQuestionNew',
        {
          headers: {
            Authorization: token,
          },
        });
        const responseData = response.data;
        if (responseData.Message === 'Success' && responseData.Data.length > 1) {
          setQuestions(responseData.Data);
          setStartTest(true);
          const start = new Date();
          setTSTime(start);
        } else {
          console.error('Failed to fetch questions:', responseData.Message);
        }
      } catch (error) {
        console.error('Error fetching questions:', error);
        setStartTest(false);
      } finally {
        setLoading(false);
      }
    };

    getQuestions();
  }, []);

  const handleOptionSelect = (optionIndex) => {
    setSelectedOption(optionIndex);
  };

  const padNumber = (num) => (num < 10 ? `0${num}` : num);

  const calculateTimeTaken = (endTime) => {
    if (TSTime) {
      const timeDifference = endTime - TSTime;
      const minutes = Math.floor(timeDifference / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
      const formattedTime = `${padNumber(minutes)} : ${padNumber(seconds)}`;  
      return formattedTime;
    }
    return null;
  };
  
  const handleNext = (selectedOption) => {
    setExamResponses((prevResponses) => {
      const updatedResponses = [...prevResponses];
      updatedResponses[currentQuestionIndex] = {
        questionId: questions[currentQuestionIndex]?.QuestionId,
        selectedOption: selectedOption,
        startTime: new Date(),
      };
      return updatedResponses;
    });
  
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    setSelectedOption(null);
  };

  const handleSubmit = async (selectedOption) => {
    if (submissionInProgress) {
      return; // If submission is already in progress, do nothing
    }
    const end = new Date();
    const updatedResponses = [...examResponses];
    updatedResponses[currentQuestionIndex] = {
      questionId: questions[currentQuestionIndex]?.QuestionId,
      selectedOption: selectedOption,
      startTime: new Date(),
    };
    setExamResponses(updatedResponses);
  
    const token = localStorage.getItem('token');
  
    try {
      const response = await axios.post(
        'https://IcreativezQuizApi.icreativez.com/api//Corporate//AddStudentExamNew',
        {
          StudentId: localStorage.getItem('userId'),
          exam: updatedResponses.map((response, index) => ({
            StudentId: localStorage.getItem('userId'),
            QuestionId: response.questionId,
            Ans: ['OA', 'OB', 'OC', 'OD'][response.selectedOption],
            TimeTaken: index === updatedResponses.length - 1 ? calculateTimeTaken(end) : 0,
            CreatedOn: response.startTime.toISOString(),
          })),
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
  
      if (response.data.Desc === 'Success') {
        setSubmissionInProgress(true);
        toast.success('Exam submitted successfully!', {
          autoClose: 1500,
        });
        setObtainedMarks(response.data.marks.ObtainedMarks);
        setTotalMarks(response.data.marks.TotalMarks);
        setTimeTaken(response.data.marks.TimeTaken);
        setResult(true);
      } else {
        toast.error('Failed to submit exam. Please try again.', {
          autoClose: 1500,
        });
      }
    } catch (error) {
      toast.error('Error submitting exam. Please check your network connection.', {
        autoClose: 1500,
      });
    }
  };
  

  useEffect(() => {
    let countdown;

    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(countdown);
  }, [timer]);

  useEffect(() => {
    if (timer === 0 && currentQuestionIndex < questions.length - 1) {
      handleNext();
    } else if (timer === 0 && currentQuestionIndex === questions.length - 1) {
      handleSubmit(); 
    }
  }, [timer, questions.length]);

  useEffect(() => {
    setTimer(45);
  }, [currentQuestionIndex]);

  return (
    <div className="mcqs-container">
      {loading ? (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <GridLoader color={'#85030a'} size={15} margin={2} />
        </div>
      ) : Result ? (
        <ResultPop />
      ) : (
        <div>
          <div className='d-flex justify-content-between align-items-center mcqMob'>
            <div className='d-flex gy-3'>
              <b className='myGradbg'>{currentQuestionIndex + 1}</b>
              <p className='ms-4 mb-0'>{questions[currentQuestionIndex]?.Question}</p>
            </div>
            <div className="myGradbg">Timer: 00:{timer < 10 ? `0${timer}` : timer}</div>
          </div>
          <div className='ms-5 ps-4 mt-3 mcqOption'>
            {['A', 'B', 'C', 'D'].map((option, index) => (
              <label key={index} style={{ display: 'block', margin: '15px 5px' }}>
                <input
                  type='radio'
                  value={questions[currentQuestionIndex]?.[`O${option}`]}
                  checked={selectedOption === index}
                  onChange={() => handleOptionSelect(index)}
                  style={{ cursor: 'pointer', marginRight: '15px' }}
                />
                {questions[currentQuestionIndex]?.[`O${option}`]}
              </label>
            ))}
          </div>

          <div className='d-flex mt-5 subButton'>
            {currentQuestionIndex < questions.length - 1 ? (
              <button onClick={()=>handleNext(selectedOption)} className='me-2 mt-3 btn btn-lg myGradbg'>
                Next
              </button>
            ) : (
              <button onClick={()=>handleSubmit(selectedOption)} className='me-2 mt-3 btn btn-lg myGradbg'>
                Submit
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MCQsTest;
