import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import Login from "./Pages/Login";
import DashboardRoutes from "./Routes/DashboardRoutes";

import { useAuth } from "./Context/AppContext";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'popper.js/dist/umd/popper.min.js'; 
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 

import './App.css'

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route
          path="/dashboard/*"
          element={isAuthenticated ? <DashboardRoutes /> : <Navigate to="/login" />}
        />
        <Route
          path="/login"
          element={
            isAuthenticated ? <Navigate to="/dashboard" /> : <Login />
          }
        />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
