import { createContext, useContext, useState, useEffect } from 'react';

const AppContext = createContext();

export const ContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [startTest, setStartTest] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userResponses, setUserResponses] = useState([]);
  const [score, setScore] = useState(0);
  const [obtainedMarks,setObtainedMarks] = useState(0);
  const [totalMarks, setTotalMarks] = useState(0);
  const [timeTaken,setTimeTaken] = useState("")

  // Check authentication status on component mount
  // useEffect(() => {
  //   const token = localStorage.getItem('token');    
  //   if (token) {
  //     fetch('https://IcreativezQuizApi.icreativez.com/api/Account/VerifyTokenNew', {
  //       method: 'GET',
  //       headers: {
  //         Authorization: token,
  //       },
  //     })
  //       .then(response => {
  //         if (response.ok) {
  //           setIsAuthenticated(true);
  //         } else {
  //           setIsAuthenticated(false);
  //         }
  //       })
  //       .catch(error => {
  //         console.error('Error verifying token:', error);
  //         setIsAuthenticated(false);
  //       });
  //   } else {
  //     setIsAuthenticated(false);
  //   }
  // }, []);
  useEffect(()=>{
    logout();
  },[]);

  const login = async () => {
    const token = localStorage.getItem('token');
    const fullName = localStorage.getItem('fullName');
    const userId = localStorage.getItem('userId');
    if (token && fullName && userId) {
          setIsAuthenticated(true);
      } else {
      setIsAuthenticated(false);
    }
  };
  

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('fullName');
    setStartTest(false);
    setIsAuthenticated(false);
  };

  const toggleSidebar = () => { 
    setSidebarOpen((prevOpen) => !prevOpen);
  };

  const testStart = () => {
    setStartTest(true);
  };

  const contextValue = {
    timeTaken,
    setTimeTaken,
    currentQuestion,
    setCurrentQuestion,
    userResponses,
    setUserResponses,
    score,
    setScore,
    isAuthenticated,
    login,
    logout,
    startTest, 
    testStart, 
    sidebarOpen, 
    toggleSidebar,
    obtainedMarks,
    setObtainedMarks,
    totalMarks,
    setTotalMarks,
    setStartTest
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AppContext);
};
