import React from "react";
import Instructions from "../../Components/Instructions";
import MCQsTest from "../../Components/MCQsTest";
import { useAuth } from "../../Context/AppContext";


const Home = () => {
  const {startTest} = useAuth();
  return (
    <div className="Main_Div">
      <div className="Home_Div mt-5">
        {startTest ? <MCQsTest /> : <Instructions />}
      </div>
    </div>
  );
};
export default Home;
