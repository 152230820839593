import React from 'react'
import { Link } from 'react-router-dom'
import { iconC,FavC } from '../Assets/Visuals'
import { useAuth } from '../Context/AppContext'

const DashboardSidebar = () => {
  const { sidebarOpen,logout } = useAuth();
  return (
    <nav className={`sidebar ${sidebarOpen ? '' : 'closed'}`} >
      <div className="logo d-flex justify-content-between" style={{height:'75px'}}>
    <a className="logo-box" >
      <img src={iconC} alt="Logo" style={{width:'120px'}}/>
    </a>
      </div>
      <ul id="sidebar_menu" className="metismenu mt-3 ps-0">
        <li className="">
        <Link to='/dashboard' aria-current="page">
            <div className="nav_icon_small">
              <img src={FavC} alt="logo" style={{width:'20px'}}/>
            </div>
            <div className="nav_title">
              <span>Quiz </span>
            </div>
          </Link>
        </li>
      </ul>
      <div className="logoutBtn d-flex justify-content-center" style={{position:'absolute',bottom:'40px',left:'90px'}}>
        <button className='btn text-white btn-lg btn-outline-danger' onClick={logout}> logout</button>
      </div>
    </nav>

  )
}

export default DashboardSidebar