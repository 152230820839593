  import React from 'react'
  import { menuIcon,FavC } from '../Assets/Visuals'
  import { useAuth } from '../Context/AppContext';

  const DashboardNavbar = () => {
    const { toggleSidebar } = useAuth();
    const fullName = localStorage.getItem('fullName');
    const userId = localStorage.getItem('userId')
    return (  
      <>
      <div className="container-fluid g-0">
        <div className="row">
          <div className="col-lg-12 p-0 ">
          <div className="header_iner d-flex justify-content-between align-items-center" style={{height:'75px'}}>
            <div className="sidebar_icon d-lg-none">
            <img src={menuIcon} alt="#" onClick={toggleSidebar} width={40}/>
            </div>
            <div className="line_icon open_miniSide d-none d-lg-block">
            <img src={menuIcon} alt="#" onClick={toggleSidebar} width={40}/>
            </div>
            <div className="header_right d-flex justify-content-between align-items-center ms-4">
              <div className="profile_info d-flex align-items-center">
                <div className="profile_thumb me-3">
                  <img src={FavC} alt="#" style={{width:'80px'}}/>
                </div>
                <div className="author_name">
                  <h4 className="fw-500 mb-0">{fullName}</h4>
                  <p className="fw-400 mb-0">{userId}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        </div>
      </div>
    </>
    )
  }

  export default DashboardNavbar